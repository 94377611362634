$aside-width = 280px

.app-aside
	size($aside-width, 100%)
	position: fixed
	overflow-x: auto
	top: 0
	left: $aside-width * (-1)
	background-color: white
	padding-inline: $global-padding
	display: flex
	flex-flow: column
	justify-content: space-between
	z-index: 10

	&.opened
		left: 0
		transition(0.25s all ease)

	&-username
		font-title()
		text-align: center
		color: white
		margin-bottom: $global-padding
		font-weight: 500
		background-color: transparentify(black, .25)
		padding: $global-size * 2
		border-radius($global-size * 2)

	+above($lg)
		left: 0

	&-logo,
	&-footer
		background-color: $app-color-primary

	&-logo
		border-radius(0 0 $global-padding $global-padding)
		padding: $global-padding * 2
		margin-bottom: $global-padding * 2
		> img
			section-row()
			max-width: 100%

	&-footer
		section-row()
		border-radius($global-padding $global-padding 0 0)
		padding: $global-padding
		margin-top: $global-padding * 2
		&-inner
			display: flex
			flex-direction: row
			justify-content: stretch
	&-nav,
	&-nav ul
	&-nav ul > li
	&-nav ul > li > a
		section-row()

	&-nav
		ul
			> li
				margin-bottom: $global-size
				> ul
					&.dropdown
						display: none
					&.active
						display: block
					background-color: $black-10
					padding: $global-size
					margin-top: $global-size
					border-radius($global-size)
					> li
						> a
							font-title(500)
							background-color: transparent
							color: darken($app-color-primary, 17.5%)
							&:hover
								background-color: transparentify(black, .025)
				&:last-child
					margin-bottom: 0
				> a
					$icon-size = 24px
					padding: $global-size * 2
					background-color: transparentify(black, .0175)
					border-radius($global-size)
					&:hover
						background-color: transparentify(black, .05)
						i,
						span
							color: darken($app-color-primary, 12.5%)
					i,
					span
						float: left
						color: $app-color-primary
					i
						square($icon-size)
						line-height: $icon-size
						~ span
							margin-left: $global-padding
					span
						height: $icon-size
						font-title()
						font-weight: 500
						font-size: $global-size * 4
						line-height: $icon-size

	&-pull
		display: block
		$size = 40px
		position: absolute
		top: $global-padding
		right: $global-padding
		square($size)
		background-color: white
		box-shadow(4px 4px 6px transparentify(black, .075))
		border-radius($global-size)
		z-index: 10
		+above($lg)
			display: none
		&:hover
			cursor: pointer
			transform-rotate(12deg)
			transition(.25s all ease)
		> i
			square($size)
			line-height: $size
			text-align: center
			font-size: $global-size * 6
			color: $black-50

.divider
	$height = 40px
	height: $height
	font-title(500)
	section-row()
	padding-block: $global-padding
	> p
		size(160px, $height)
		line-height: $height
		background-color: white
		text-align: center
		padding-inline: $global-size
		margin: 0 auto
		color: $black-75
	&::before
		content: ''
		position: absolute
		size(100%, 1px)
		background-color: $black-15
		top: ($height / 2) + $global-padding
		left: 0

.user-logo
	$logo-size = 80px
	flex: $logo-size 0 0
	square($logo-size)
	border-radius(50%)
	overflow: hidden
	border: 4px solid white
	> img
		float: left
		display: block
		square(100%)
		object-fit: cover
	~ .user-nav
		margin-left: $global-size * 2

.user-nav
	width: 100%
	li,
	li > a
		section-row()
	li
		margin-bottom: $global-size
		&:last-child
			margin-bottom: 0
		> a
			$icon-size = 16px
			padding: $global-size
			background-color: transparentify(white, .025)
			border-radius($global-size)
			&:hover
				background-color: transparentify(white, .0325)
			i,
			span
				float: left
				color: white
			i
				square($icon-size)
				line-height: $icon-size
				~ span
					margin-left: $global-size * 2
			span
				height: $icon-size
				font-title(500)
				font-size: $global-size * 3
				line-height: $icon-size
