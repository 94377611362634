.snackbar
	position: fixed
	bottom: $global-padding
	right: ($global-padding * 2)
	display: flex
	flex-direction: column-reverse
	align-items: right
	z-index: 999999

	&__item
		float: right
		display: flex
		align-items: center
		clear: both
		padding: $global-padding
		background-color: $black-85
		max-width: (320px - ($global-padding * 2))
		margin-bottom: 5px
		margin-right: -500px
		border-radius(2px)
		box-shadow(-1px 1px 3px transparentify(black, .125))
		opacity(0)
		transition(.5s all ease-in)

		span,
		p
			roboto(500)
			color: white
			font-size: 13px
			line-height: 17px
			padding: 0
			margin: 0

		&:hover
			cursor: pointer
			background-color: black
			box-shadow(-5px 5px 15px transparentify(black, .25))

		&.error
			background-color: $color-red-400
			&:hover
				background-color: $color-red-600

		&.success
			background-color: $color-blue-400
			&:hover
				background-color: $color-blue-600
