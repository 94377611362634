.filter-list
	section-row()
	margin-bottom: $global-padding

	&.full-height
		height: 100%
		display: flex
		flex-direction: column
		justify-content: flex-start
		.filter-list-options
			height: 100%
	&.limited
		.filter-list-options
			overflow: hidden
			max-height: 280px
			overflow-y: auto

	h2,
	h3,
	h4,
	h5
		font-size: $global-size * 3
		line-height: $global-size * 4
		margin-bottom: $global-size * 2

	&-options
		section-row()
		border: 2px solid $black-10
		border-radius($global-size)

		&.expand
			height: auto
			max-height: none

		.input-checkbox
			margin: 0
			padding: $global-padding ($global-padding / 2)
			border-bottom: 1px solid $black-10
			&:hover
				background-color: $black-05
				cursor: pointer
			&:last-child
				border-bottom: 0

	&-footer
		float: left
		display: block
		margin-top: ($global-padding / 3)
		li
			$h = 25px
			height: $h
			margin-right: ($global-padding / 5)
			padding: 0 ($global-padding / 3)
			background-color: $black-05
			float: left
			display: inline-block
			border-radius($global-padding / 5)
			&:hover
				background-color: $black-15
				cursor: pointer
				i,
				.mi,
				.mdi,
				span
					color: black
			i,
			.mi,
			.mdi,
			span
				float: left
				display: inline-block
				line-height: $h
				color: $black-75
			i,
			.mi,
			.mdi
				font-size: 20px
				margin-right: ($global-padding / 5)
			span
				color: $black-65
				font-size: 12px
