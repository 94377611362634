.form
	section-row()
	&-group
		section-row()
		margin-bottom: $form-row-margin-bottom
		&.has-error
			input
				border-color: $color-red
				~ label
					color: $color-red
		&.display
			padding: $app-padding
			margin-bottom: 0
			border-bottom: 1px solid $black-05
			&:last-child
				border-bottom: 0
		&.inline
			width: auto
			float: left
			display: inline-block
			margin-right: ($app-padding / 3)
			margin-bottom: ($app-padding / 4)
			&:last-child
				margin-right: 0
				margin-bottom: 0
		&.low-margin
			margin-bottom: ($form-row-margin-bottom / 5)
	&-control
		&-helper
			section-row()
			padding-right: 20%
			font-size: 12px
			line-height: 14px
			color: $black-50
			font-ttile(500)
			margin-top: ($app-padding / 3)

	&-validation
		section-row()
		margin-bottom: ($app-padding * 2)
		&.errors
			background-color: $color-red
			border-radius($app-padding / 5)
		li
			section-row()
			padding: ($app-padding / 2)
			font-title(500)
			font-size: 13px
			line-height: 17px
			margin-bottom: ($app-padding / 2)
			color: white
			&:last-child
				margin-bottom: 0

.invalid-feedback
	section-row()
	open-sans()
	border-radius(3px)
	background-color: $black-05
	color: $black-90
	font-size: 12px
	line-height: 16px
	padding: ($app-padding / 3)
	margin-top: ($app-padding / 3)
	strong
		font-weight: 700