.media-modal
	section-row()
	background-color: white
	min-height: 100%
	border-radius(3px)
	box-shadow(0 2px 4px transparentify(black, .35))

	.card-header,
	.card-footer,
	.card-body
		padding: $global-padding
	.card-header
		border-bottom: 1px solid $black-05
		h2,
		h3
			margin-bottom: 0
	.card-footer
		border-top: 1px solid $black-05

	&-wait
		square(100%)
		position: fixed
		left: 0
		top: 0
		text-align: center
		padding: $app-padding
		background-color: transparentify(black, .75)
		flex-direction: column
		justify-content: center
		align-items: center
		z-index: 9999
		display: none
		&.active
			display: flex
			transition(0.25s all ease)
		p,
		small
			font-text(500)
			section-row()
			color: white
		p
			margin-top: ($app-padding * 4)
			font-size: 14px
			line-height: 18px
		small
			font-weight: 400
			font-size: 12px
			line-height: 14px
			padding-left: ($app-padding * 2)
			padding-right: ($app-padding * 2)

	&-wrapper
		position: fixed
		top: 0
		left: 0
		square(100%)
		min-height: 100%
		overflow-y: auto
		padding: ($app-padding * 1.5)
		display: none
		background-color: transparentify(black, .75)
		z-index: 500

		&.down-1
			z-index: 490
		&.down-2
			z-index: 480
		&.down-3
			z-index: 470

	&-container
		width: 100%
		max-width: ($lg * 1.5)
		margin: 0 auto
		display: inline

	&-close
		$size = 40px
		square($size)
		position: absolute
		top: ($app-padding / 1.5)
		right: ($app-padding / 1.5)
		overflow: hidden
		> i,
		> .mdi
			square($size)
			max-width: $size
			float: left
			display: block
			text-align: right
			line-height: $size
			font-size: 28px
			margin: 0
			padding: 0
		&:hover
			cursor: pointer
			> i,
			> .mdi
				color: $color-green

.media-item
	section-row()
	height: 220px
	border-radius(3px)
	overdlow: hidden
	margin-bottom: $app-padding
	background-color: $black-05
	+below($lg)
		height: 180px
	+below($md)
		height: 160px
	+below($sm)
		height: 140px

	&.auto-height
		height: auto !important

	&:before
		content: ""
		size(100%, 25%)
		gradient('to bottom', transparentify(black, .25), transparent)
		border-radius(3px 3px 0 0)
		position: absolute
		top: 0
		left: 0
		z-index: 1

	img
		square(100%)
		min-width: 100%
		min-height: 100%
		float: left
		display: block
		object-fit: contain

	figcaption
		size(100%, auto)
		position: absolute
		bottom: 0
		left: 0
		background-color: transparentify($app-dark-green, .75)
		color: white
		padding: $global-padding * .75
		overflow-wrap: break-word

	.checkbox-container
		margin-top: ($app-padding / 3)

.image-select
	$size = 35px
	section-row()
	border-radius(3px)
	overflow: hidden
	img
		section-row()
		min-width: 100%
	&-options
		position: absolute
		top: $app-padding
		right: $app-padding
		z-index: 25
		li
			float: left
			display: inline-block
			margin-right: ($app-padding / 3)
			background-color: $black-90
			box-shadow(0 2px 4px transparentify(black, .125))
			border-radius(3px)
			overflow: hidden
			&:last-child
				margin-right: 0
			&:hover
				cursor: pointer
				background-color: $black-80
				box-shadow(0 3px 8px transparentify(black, .25))
			&.green
				background-color: $color-green
				&:hover
					background-color: $color-green-400
					i,
					.mdi
						color: black
			&.blue
				background-color: $color-blue
				&:hover
					background-color: $color-cyan
					i,
					.mdi
						color: black
			&.red
				background-color: $color-red
				&:hover
					background-color: $color-deep-orange
					i,
					.mdi
						color: black
			i,
			.mdi
				square($size)
				line-height: $size
				text-align: center
				color: white

.album-gallery
	$height = 180px
	section-row()
	transition(0.2s all ease)
	+below($md)
		margin-bottom: ($app-padding * 2)
	&:hover
		cursor: pointer
		transition(0.3s all ease)
		transform-rotate(4deg)
		.album-gallery-back-content
			transition(0.2s all ease)
		.album-gallery-back-content-1
			transform-rotate(-3deg)
			transition(0.2s all ease)
		.album-gallery-back-content-2
			transform-rotate(-5deg)
			transition(0.25s all ease)
		.album-gallery-back-content-3
			transform-rotate(-7deg)
			transition(0.3s all ease)
		.album-gallery-back-content-4
			transform-rotate(-9deg)
			transition(0.35s all ease)
		.album-gallery-back-content-5
			transform-rotate(-11deg)
			transition(0.4s all ease)
	&-content,
	&-back-content
		section-row()
		height: $height
		border-radius(3px)
		overflow: hidden

	&.with-media
		.album-gallery-content
			background-color: transparentify(black, .4)
			&__count,
			&__text,
			&__small
				color: white
				text-shadow(1px 2px transparentify(black, .25))
			&__count
				text-shadow(2px 8px transparentify(black, .25))

	&-content
		background-color: $black-05
		z-index: 10
		display: flex
		flex-direction: column
		padding: $app-padding
		justify-content: center
		&__count,
		&__text,
		&__small
			text-align: center
			margin: 0
			padding: 0
		&__count
			open-sans(700)
			font-size: 38px
			line-height: 42px
			color: $black-75
		&__text
			open-sans(500)
			font-size: 18px
			line-height: 22px
			color: black
		&__small
			font-text(700)
			font-size: 10px
			line-height: 14px
			color: $black-25

	&__images
		square(100%)
		position: absolute
		top: 0
		left: 0
		display: flex
		z-index: 9
		figure
			img
				square(100%)
				float: left
				display: inline-block
				object-fit: cover
				object-position: center top

	&-back-content
		transition(0.2s all ease)
		position: absolute
		left: 0
		top: 0
		border: 2px solid transparentify(white, .05)
	&-back-content-1
		background-color: $black-25
		z-index: 5
	&-back-content-2
		background-color: $black-25
		z-index: 4
	&-back-content-3
		background-color: $black-35
		z-index: 3
	&-back-content-4
		background-color: $black-45
		z-index: 2
	&-black-content-5
		background-color: $black-55
		z-index: 1

.media-checkbox
	section-row()
	border: 5px solid $black-50
	overdlow: hidden
	border-radius(3px)
	background-color: $black-50
	figure,
	figure > img
		section-row()
	figure
		border-radius(3px)
		overflow: hidden
		img
			max-width: 100%
			min-width: 100%
		figcaption
			size(100%, 30%)
			position: absolute
			bottom: 0
			right: 0
			gradient('to top', black, transparent)
			p
				font-text(500)
				font-size: 13px
				line-height: 17px
				color: white
				width: 100%
				position: absolute
				left: 0
				bottom: 0
				padding: $app-padding
				margin: 0
	&.active
		box-shadow(0 2px 4px transparentify(black, .25))
		border-color: $color-cyan
		background-color: $color-cyan
	&:hover
		cursor: pointer

.media-bottom-text
	$bg = $black-75
	$size = 10px
	$color = white

	section-row()
	open-sans()
	padding: ($app-padding / 2)
	border-radius: 3px
	font-size: 12px
	line-height: 16px
	color: white
	background-color: $bg
	margin-top: ($app-padding / 4)
	box-shadow(0 2px 4px transparentify(black, .125))
	&:before
		square($size)
		content: ""
		position: absolute
		right: ($app-padding / 3) + $size
		top: ($size / 2) * (-1)
		background-color: $bg
		transform-rotate(45deg)

.media-list
	section-row()
	display: flex
	margin-bottom: $app-padding
	border-bottom: 1px solid $black-05

	&.simple
		border-radius($global-size)
		margin-bottom: none
		&:last-child
			margin-bottom: $global-size * 3
		&:nth-child(odd)
			background-color: $black-05
			p,
			.badge
				color: black
		.media-list-content
			padding: $global-size * 4
			margin-bottom: 0
		p
			font-size: $global-size * 3.5
			line-height: $global-size * 4.5
			margin-bottom: 0
			+below($md)
				margin-bottom: $global-size * 2
		.badges
			margin-bottom: 0
			.badge
				font-size: $global-size * 2.5
				line-height: $global-size * 3
		+above($md)
			.badge
				margin-bottom: 0 !important

	&:last-child
		border-bottom: none
		&-media
			border-bottom: 0

	&.inactive
		.media-list
			&-media
				background-color: $black-25
				color: $black-50
			&-content
				h2,
				h3,
				h4
					color: $black-25
					a
						color: $black-25
						&:hover
							color: $black-50
					~ small
						color: $black-15
						a
							color: $black-20
							&:hover
								color: $black-40
						span
							color: $black-15

	&-checkbox
		margin-right: ($app-padding / 2)
		margin-top: ($app-padding * 1.25)

	&-media
		$size = 60px
		square($size)
		border-radius(3px)
		overflow: hidden
		background-color: $black-95
		border-bottom: 1px solid $black-05
		float: left
		span
			font-text(300)
			square(100%)
			max-width: 100%
			text-align: center
			line-height: $size
			float: left
			display: block
			font-size: 20px
			background-color: transparent
			color: white

	&-content
		padding-left: $app-padding
		width: 100%

		h2,
		h3,
		h4
			font-size: 20px
			line-height: 24px
			section-row()
			margin-bottom: ($app-padding / 3)
			> a
				color: black
				&:hover
					color: $color-blue
					cursor: pointer
			~ small
				section-row()
				font-text(500)
				border: none
				color: black
				margin-top: ($app-padding / 5 * (-1))
				margin-bottom: ($app-padding / 3)
				a
					color: $color-blue
					&:hover
						text-decoration: underline
				span
					background-color: $black-05
					padding: 0 ($app-padding / 5)
		.badges
			margin-bottom: $app-padding
