.page
	h1,
	p
		section-row()
	h1
		font-size: $global-padding * 4
		line-height: ($global-padding * 4) + $global-size
	p
		font-size: $global-size * 5
		line-height: $global-size * 6
		margin-bottom: $global-padding * 2
