.btn,
.btn:visited
	size(auto, $input-height)
	font-title()
	font-size: 14px
	line-height: $input-height
	border: 0
	text-align: center
	padding: 0 $input-hor-padding
	color: $app-color-accent
	background-color: $app-color-primary
	float: left
	border-radius($global-size)

	+above($lg)
		&.inline-with-input
			margin-top: $global-size * 5

	i,
	.mdi,
	.mi
		top: ($global-padding / 2)
		margin-right: ($global-padding / 2)
		&.end
			margin-right: 0
			margin-left: ($global-padding / 2)

	&.no-float
		float: none
	&.block
		width: 100%
		float: left
		diplay: block
	&.auto-height
		height: auto
		padding-top: ($global-padding / 3)
		padding-bottom: ($global-padding / 3)
	&.rounded
		border-radius($input-height)
		&.auto-height
			border-radius($global-padding)
	&:hover,
	&:focus
		color: $black-05
		background-color: $app-color-3rd
		box-shadow(0 1px 3px transparentify(black, .125))
	&:hover
		cursor: pointer
	&:focus
		box-shadow(0 2px 6px transparentify(black, .25))
	&.transparent,
	&.btn-transparent
		background-color: transparentify(black, .025)
		color: $app-color-primary
		&:hover
			background-color: transparentify(black, .075)
			color: $app-color-3rd
			box-shadow(none)
	&.blue,
	&.btn-blue
		background-color: $color-blue
		color: white
		&:hover
			background-color: $color-blue-700
	&.green,
	&.btn-green
		background-color: $color-green
		color: white
		&:hover
			background-color: $color-green-700
	&.orange,
	&.btn-orange
		background-color: $color-deep-orange
		color: white
		&:hover
			background-color: $color-orange
			color: white
	&.disabled,
	&.disabled:hover,
	&.disabled:focus
		background-color: $black-05
		color: $black-20
		pointer-events: none
		&:before
			content: ""
			square(100%)
			position: absolute
			left: 0
			top: 0
			background-color: transparent
			z-index: 5
.btn-small
	$h = 26px
	$p = 12px
	height: $h
	font-size: 10px
	line-height: $h
	padding: 0 $p

.btn-big
	$h = 60px
	$p = 20px
	height: $h
	font-size: 18px
	line-height: $h
	padding: 0 $p

.btns
	section-row()
	&.left,
	&.right
		display: flex
	&.left
	&.right
		justify-content: right
	&.center
		text-align: center
		.btn
			float: none
			margin: 0 auto
			display: inline-block
	.btn
		margin-right: ($global-padding / 2)
		&:last-child
			margin-right: 0
		+below($sizes.md)
			width: 100%
			margin-right: 0
			margin-bottom: ($global-padding / 2)
			&:last-child
				margin-bottom: 0
