.image-card
	section-row()
	border-radius($global-size)
	overflow: hidden
	&:hover
		margin-bottom: $global-size
		box-shadow(0 4px 8px transparentify(black, .25))
		transition(.25s all ease)
		figcaption
			bottom: $global-padding
			transition(0.25s all ease)
			h3
				background-color: $app-orange
		.image-card-shadow
			height: 75%
			transition(0.2s all ease)
			gradient('to top', transparentify(black, .875), transparent)
	> img
		section-row()
	figcaption
		padding: $global-padding
		position: absolute
		left: 0
		bottom: 0
		z-index: 1
		transition(.25s all ease)
		h3,
		p
			section-row()
		h3
			background-color: transparentify($app-orange, .75)
			padding: ($global-size * 2) $global-padding
			margin-left: $global-padding * -1
			border-radius(0 $global-size $global-size 0)
			transition(.2s all ease)
		p
			color: white
			font-size: $global-padding
			font-weight: 600
			margin: 0
			transition(0.325s all ease)
	&-shadow
		size(100%, 50%)
		position: absolute
		left: 0
		bottom: 0
		gradient('to top', transparentify(black, .825), transparent)
