.form-wizard
	.form-section
		position: absolute
		visibility: hidden
		display: none
		opacity(0)
		z-index: 0
		&.current
			position: relative
			display: block
			visibility: visible
			opacity(1)
			z-index: 5

	h3
		font-size: 44px
		line-height: 48px
		color: $app-orange

	label
		font-size: $global-size * 6
		line-height: $global-size * 7
		color: $app-green
		font-weight: 600

	.input
		border: 2px solid $app-dark-green
		margin-bottom: $global-size
		height: 46px
		font-size: 22px
		color: $app-dark-green

	.form-helper
		section-row()
		font-title()
		color: $app-orange
		max-width: 75%

.form-navigation
	transition: all 1.75s ease-in-out
	-moz-transition: all 1.75s ease-in-out
	-webkit-transition: all 1.75s ease-in-out
