input[type="text"],
input[type="password"],
select,
button,
textarea,
.input,
.select
	font-text()
	section-row()
	border: $input-border-size solid $input-border-color
	height: $input-height
	background-color: $input-bg
	padding: 0 $input-hor-padding
	border-radius($input-border-radius)
	margin-bottom: $global-padding;
	&:focus
		outline: none
	&:focus
		border-color: $input-active-border-color
		box-shadow($input-active-box-shadow)
		~ label
			left: 0
			top: ($input-label-size * 1.75) * (-1)
			padding-left: 0
			background-color: transparent
			color: $input-label-active-color
			transition(0.2s all ease)

textarea,
.textarea
	min-width: 100%
	height: auto
	padding: $input-hor-padding
	min-height: $global-padding * 6
	&.textarea-sm
		min-height: $global-padding * 4
	&.textarea-lg
		min-height: $global-padding * 10
	&.textarea-xl
		min-height: $global-padding * 14
	&.textarea-xll
		min-height: $global-padding * 18

.input-file
	section-row()
	input
		display: none
	label
		section-row()
		color: $black-75
		padding: $app-padding
		border-radius($global-size)
		background-color: $black-05
		border: 2px dashed $black-15
		&:hover
			border-color: $black-30
			background-color: $black-10
			color: black
			cursor: pointer
