.app-footer
	section-row()
	background-color: $black-20
	margin-top: $global-padding * 2
	&-content
		width: 100%
		float: left
		display: flex
		align-content center
		align-items: center
		justify-content: center
		flex-direction: column
		padding: ($global-padding * 2) 0
	&-logo
		background-color: $app-dark-green
		border-radius($global-padding)
		text-align: center
		padding: $global-padding
		width: 280px
		box-shadow(-2px 8px 8px transparentify(black, .175))
		> img
			section-row()
	> small
		section-row()
		font-title()
		background-color: transparentify(black, .25)
		padding: $global-padding
		text-align: center
		color: $app-dark-green
