// Grid
@import '../../node_modules/s-grid/s-grid-settings'
@import '../../node_modules/s-grid/s-grid-functions'

gridClassName = 'grid'
cellClassName = 'cell'
gutter = rem-calc($app-padding)
breakpoints = {
	sm: $xs,
	md: $sm,
	lg: $md,
	xlg: $lg,
	xxlg: $xl
}

@import '../../node_modules/s-grid/s-grid-classes'

.{gridClassName}-stretch
	align-items: stretch
	align-content: stretch

.row
	section-row()
	grid(direction: 'row', cells-align: 'top', justify: 'start')

	&.centered
		place-content: center

	&.stretch
		justify-items: stretch

.row,
.{gridClassName}
	margin-left: (gutter / 2) * (-1)
	width: calc(gutter, 100%, '+')

.col
	&-02,
	&-2,
	&-025,
	&-25,
	&-03,
	&-3,
	&-04,
	&-4,
	&-06,
	&-6,
	&-08,
	&-8,
	&-09,
	&-9,
	&-12
		stack()
		float: left
		margin-top: 0 !important
		margin-bottom: 0 !important

		&.stretch
			align-self: stretch !important
			display: flex !important

	+above(rem-calc(breakpoints.lg))
		&-02,
		&-03,
		&-2,
		&-3
			cell(1, 4, 'top', g: $app-padding)
		&-025,
		&-25
			cell(1, 5, 'top', g: $app-padding)
		&-04,
		&-4
			cell(1, 3, 'top', g: $app-padding)
		&-06,
		&-6
			cell(1, 2, 'top', g: $app-padding)
		&-08,
		&-8
			cell(2, 3, 'top', g: $app-padding)
		&-09,
		&-9
			cell(3, 4, 'top', g: $app-padding)

	+above(rem-calc(breakpoints.xlg))
		&-02,
		&-2
			cell(1, 6, 'top', g: $app-padding)

.inline-col
	&-02,
	&-03,
	&-04,
	&-05,
	&-06,
	&-07,
	&-08,
	&-09,
	&-10,
	&-11,
	&-12
		float: left
		&.stretch
			align-self: stretch !important
			display: flex !important
	&-02,
	&-03,
	&-2,
	&-3
		cell(1, 4, 'top', g: $app-padding)
	&-04,
	&-4
		cell(1, 3, 'top', g: $app-padding)
	&-06,
	&-6
		cell(1, 2, 'top', g: $app-padding)
	&-08,
	&-8
		cell(2, 3, 'top', g: $app-padding)
	&-09,
	&-9
		cell(3, 4, 'top', g: $app-padding)
