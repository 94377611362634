// -------- //
// DARK BOX //
// -------- //

.dark-box
	square(100%)
	position: fixed
	left: 0
	top: 0
	background-color: transparentify(black, .85)
	z-index: 9999
	display: none
	overflow-x: hidden
	&__btn
		z-index: 15
	&__overlay
		z-index: 5
		position: fixed
		square(100%)
		left: 0
		top: 0

// ----- //
// MODAL //
// ----- //

$modal = {
	width: 580px,
	margin: 100px
}

[data-modal][data-to]:hover
	cursor: pointer

.modal
	section-row()
	background-color: white
	position: absolute
	top: 100%
	left: 0
	width: 100%
	padding: ($app-padding * 2)
	min-height: 50%
	transition(0.2s all ease)
	&.opened
		transition(0.25s all ease)
		top: 50%
	&-container,
	&-body
		section-row()
	h1,
	h2,
	h3,
	h4,
	p
		section-row()
		margin-bottom: $app-padding

	h1,
	h2,
	h3
		lato(900)
		color: $app-blue
		font-size: 20px
		line-height: 24px
	h4
		lato(700)
		font-size: 17px
		line-height: 21px
		color: $black-85
		margin-top: $app-padding
	h1,
	h2,
	h3,
	h4
		~ span,
		~ small
			section-row()
			roboto(500)
			color: $black-50
			font-size: 13px
			line-height: 17px
			margin-top: ($app-padding / 2) * (-1)
	p
		open-sans(400)
		font-size: 16px
		line-height: 20px
		color: $black-85

	.badges
		~ p
			margin-top: $app-padding

	figure,
	figure img
		section-row()
	figure
		border-radius(3px)
		overflow: hidden
		margin-bottom: $app-padding
		img
			max-width: 100%

	.content-list-graphs
		width: 100%
		min-width: 100%
		max-width: 100%

	&-close
		$size = 40px
		square($size)
		position: absolute
		right: 0
		top: $app-padding * (-5.5)
		background-color: transparentify(black, .525)
		border-radius(5px)
		overflow: hidden
		&:hover
			cursor: pointer
			transition(0.2s all ease)
			transform-rotate(90deg)
		.mdi,
		i
			square(100%)
			font-size: 32px
			line-height: $size
			text-align: center
			color: white

	+above($md)
		top: ($app-padding * 9.5)
		bottom: auto
		margin-top: 0
		background-color: transparent
		padding: 0
		transition(0.2s all ease)
		opacity(0)
		&.opened
			transition(0.25s all ease)
			opacity(1)
			top: ($app-padding * 9.5)
		&-container
			float: none
			width: $sm
			max-width: 90%
			margin: 0 auto
			&-md
				width: $md
			&-lg
				width: $lg
			&-xl
				width: $xl
		&-body
			background-color: white
			padding: ($app-padding * 2)
			border-radius(3px)
			box-shadow(0 2px 4px transparentify(black, .125))
			margin-bottom: ($app-padding * 2)
		&-close
			top: $app-padding * (-3.5)
		h1,
		h2,
		h3
			font-size: 30px
			line-height: 34px
		p
			font-size: 18px
			line-height: 22px
