.input-checkbox
	section-row()
	&:hover
		cursor: pointer
	> label
		display: flex
		align-items: center
		> span
			font-title(500)
			font-size: $global-padding - ($global-size / 2)
			line-height: $global-padding - ($global-size / 2) + $global-size

	input[type="checkbox"],
	input[type="radio"]
		square($global-padding)
		~ span
			margin-left: ($app-padding / 3)
		&::before
			content: ""
			square($global-padding)
			position: absolute
			left: 0
			top: 0
			background-color: white
			border: 2px solid $app-color-primary
			border-radius($global-size)
		&:checked::before
			background-color: $app-color-primary
			background: url('../../../images/icons/icon-check.svg') no-repeat center center
			background-size: 95%
