// ------ //
// LOADER //
// ------ //

$loader = {
	color1: $color-green,
	color2: $color-blue,
	color3: $color-red,
	color4: $color-yellow,
	width: 100px,
	zoom: 1.7,
	margin: 10px
}

.pre-loader
	width: $loader.width
	height: $loader.width
	zoom: $loader.zoom
	position: absolute
	top: 50%
	left: 50%
	margin-top: ($loader.width / 2) * (-1)
	margin-left: ($loader.width / 2) * (-1)
	border-radius(3px)
	
	&__circular
		animation: rotateBox 2s linear infinite
		square($loader.width)
		transform-origin: center center
		position: relative
		margin: auto
	
	&__path
		stroke-dasharray: 1,200
		stroke-dashoffset: 0
		animation: dashColor 1.5s ease-in-out infinite, colorChange 6s ease-in-out infinite
		stroke-linecap: round
	
	&__close
		position: absolute
		top: $loader.margin
		right: $loader.margin
		padding: $loader.margin
		z-index: 25
		border-radius(3px)
		> span
			font-size: 11px
			padding-right: $loader.margin
			font-weight: 500
			text-align: left
		> span,
		> .material-icons,
		> i
			float: left
			display: inline-block
			line-height: 11px
			color: white
		.material-icons,
		i
			text-align: right
		&:hover
			background-color: fade(white,25%)
			box-shadow(-1px -1px 3px fade(black,25%))
			cursor: pointer
	
@keyframes rotateBox
	100%
		transform-rotate(360deg)

@keyframes dashColor
	0%
		stroke-dasharray: 1,200
		stroke-dashoffset: 0	
	50%
		stroke-dasharray: 89,200
		stroke-dashoffset: -35
	100%
		stroke-dasharray: 89,200
		stroke-dashoffset: -124

@keyframes colorChange
	0%,
	100%
		stroke: $loader.color1
	40%
		stroke: $loader.color2
	66%
		stroke: $loader.color3
	80%,
	90%
		stroke: $loader.color4
	

.dark-box-loader
	max-width: 240px
	padding: $app-padding
	background-color: transparentify(black, .25)
	border: 2px solid transparentify(white, .025)
	border-radius($global-size)
	box-shadow(0 2px 4px transparentify(black, .25))
	+above($md)
		max-width: 420px
	+above($lg)
		max-width: 520px
	h2,
	p
		color: white
		text-align: center
	h2
		font-size: 18px
		line-height: 22px
		~ p
			margin-top: $global-size * 2
	p
		roboto(500)
		font-size: 14px
		line-height: 18px
		text-align: center

	&__container
		top: 0
		left: 0
		square(100%)
		display: flex
		position: absolute
		align-items: center
		place-content: center
		flex-flow: column
