.form-label
	font-title(500)
	section-row()
	font-size: $global-padding - ($global-size * .75)
	line-height: $input-label-line-height
	color: $input-label-color
	background-color: transparent
	margin-bottom: $global-size
	border-radius($input-border-radius)
	transition(0.2s all ease)
	> small
		font-title()
		font-size: .85em
		color: $black-50
		margin-left: ($app-padding / 5)
		width: auto
		float: right
		display: inline-block