.app-login
	width: 100%
	display: flex
	height: 100%
	flex-direction: row
	justify-content: stretch
	align-content: stretch
	&-photo,
	&-content
		height: 100%
	&-photo
		flex: 100%
		position: relative
		background-color: $black-75
		> .app-logo
			left: $global-padding
			box-shadow(0 4px 8px transparentify(black, .25))
		> img
			square(100%)
			position: absolute
			top: 0
			left: 0
			object-fit: cover
			object-position: center
	&-content
		background-color: white
		padding: $global-padding
		display: flex
		align-items: center
		form
			section-row()
		+above($md)
			flex: 620px 0

	&-video
		min-height: 100%
		min-width: 100%
		position: fixed
