.list-item
	section-row()
	margin-bottom: $global-padding
	strong,
	p
		section-row()
	strong
		text-title()
		color: $app-dark-green
		border-bottom: 1px solid $black-15
		padding-bottom: $global-size * 2
		margin-bottom: $global-size

.list-results
	section-row()
	li
		width: 100%
		float: left
		display: flex
		align-content: center
		padding-block: $global-padding
		border-bottom 1px solid $black-05
		justify-content: space-between
		flex-flow: row
		&:last-child
			border: none
		&.emphasis
			p
				font-weight: bold
		p
			width: auto !important
			color: $black-75
			margin: 0
			padding: 0
			~ p
				padding-left: $global-padding

.list-dropdown
	$list-dropdown-width = 140px
	$list-dropdown-icon-size = 30px
	position: absolute
	right: $global-size * 2
	top: $global-size * 2
	z-index: 30
	&.invert
		> li
			.mdi,
			i,
			ul > li,
			ul > li > a,
			ul > li > span
				color: black
	&.margin
		right: $app-padding
		top: $app-padding
	&.invert
		> li
			.mdi,
			i
				color: black
	> li
		margin-right: ($app-padding / 3)
		display: inline-block
		float: left
		&:last-child
			margin-right: 0
		.mdi,
		i
			square($list-dropdown-icon-size)
			text-align: center
			line-height: $list-dropdown-icon-size
			z-index: 25
			color: white
			overflow: hidden
			font-size: 20px
			&:hover
				cursor: pointer
		> ul
			width: $list-dropdown-width
			max-width: $list-dropdown-width
			position: absolute
			top: ($app-padding / 2)
			right: ($app-padding / 2)
			background-color: white
			border-radius(3px)
			overflow: hidden
			display: none
			box-shadow(0 2px 4px transparentify(black, .25))
			z-index: 35
			&.active
				display: block
			> li
				section-row()
				border-bottom: 1px solid $black-05
				&:last-child
					border-bottom: 0
				> a,
				> span
					section-row()
					roboto(400)
					font-size: 14px
					line-height: 18px
					color: $black-75
					text-align: center
					padding: ($app-padding / 2) $app-padding
					&:hover
						cursor: pointer
						color: $color-1st
						background-color: transparentify(black, .05)
