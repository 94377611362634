.app-table
	margin-block: $global-padding
	width: 100%
	float: left
	overflow-x: auto
	white-space: nowrap
	display: block
	thead,
	tbody
		width: 100%
		display: table
	th,
	td
		padding: $global-padding
		text-align: left
		vertical-align: middle
		&.text-align-right
			text-align: right
	th
		font-title()
		font-size: $global-padding
		line-height: $global-padding + $global-size
		color: $app-color-primary
	td
		font-text()
		font-size: $global-padding
		line-height: $global-padding + $global-size
		color: $black-75
	tbody
		tr
			border-radius($global-size)
			&:nth-child(even)
				background-color: $black-05
			a:not(.btn),
			a:not(.btn):visited
				color: $color-blue
				&:hover
					text-decoration: underline

	&.inner-table
		margin: 0
		tr
			padding: 0
			&:nth-child(odd),
			&:nth-child(even)
				background-color: transparent
			td
				padding: $global-size * 2 $global-size
				font-size: $global-size * 3
				line-height: $global-size * 3
