.input-file
	float: left
	display: block
	&::file-selector-button
		font-title()
		color: $app-dark-green
		padding: ($global-size * 2) $global-padding
		background-color: $black-15
		border-radius($global-size)
		border: 2px solid transparent
		&:hover
			border-color: transparentify(black, .25)
