.line-content
	section-row()
	margin-bottom: $global-padding
	b,
	strong,
	p
		section-row()
	b,
	strong
		font-title()
		border-bottom: 1px solid $black-15
		padding: $global-size * 2 0
		color: $app-green
		font-size: $global-size * 3
	p
		color: $black-90
		a
			color: $color-blue
			text-decoration: underline
			&:hover
				color: $color-blue-700
				background-color: $black-05
