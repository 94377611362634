.icon-with-text
	$size = 140px
	width: 100%
	float: left
	display: flex
	align-items: center
	align-content: center
	justify-content: center
	flex-direction: column
	background-color: white
	padding: $global-padding
	border-radius($global-size)
	margin-top: ($size / 2)
	margin-bottom: $global-padding
	box-shadow(0 4px 8px transparentify(black, .125))
	&-image
		square($size)
		margin-top: (($size / 2) + $global-padding) * (-1)
		overflow: hidden
		padding: $global-padding
		background-color: $black-10
		border-radius(50%)
		margin-bottom: $global-padding
		border: $global-size solid $black-15
		> img
			square(100%)
			float: left
			display: block
			object-fit: contain
	&-title,
	p
		text-align: center
	p
		margin-bottom: 0 !important
	&-title
		font-title()
		color: $app-green
		font-size: $global-size * 7
		line-height: $global-size * 8
		margin-bottom: $global-size * 2 !important
		b,
		strong
			color: $app-dark-green
