.container
	width: 100%
	max-width: 100%
	display: flex
	flex-direction: column
	&.container-block
		.container-inner
			display: block
	&-inner
		width: 100%
		max-width: 97.5%
		margin-left: auto
		margin-right: auto
	&-wrapper
		&-default
			margin-top: 0
			margin-bottom: 0
		&-sm,
		&-small
			margin-top: $global-padding * 2
			margin-bottom: $global-padding * 2
		&-md,
		&-medium
			margin-top: $global-padding * 4
			margin-bottom: $global-padding * 4
		&-large,
		&-lg
			margin-top: $global-padding * 6
			margin-bottom: $global-padding * 6

// Looping screen sizes
for key, value in $sizes
	if value > 0
		.{key}
			width: value - ($global-padding * 2)
			@extend .container-inner

.no-max-w
	max-width: 100% !important
