// LARANJA.INFO - MARKETING DIGITAL & WEBDESIGN
// Pack de Mixins para Stylus
//
// Muitas mixins foram retiradas de https://gist.github.com/batmandarkside/5028368
//
// @author Rafhael Marsigli <rafhael@laranja.info>
// @url laranja.info


// --------------------------- //
// ----- TRANSFORMATIONS ----- //
// --------------------------- //

transform(value)
	transform: value
	-webkit-transform: value
	-moz-transform: value
	-o-transform: value

transform-origin(value)
	-webkit-transform-origin: value
	-moz-transform-origin: value
	-ms-transform-origin: value
	-o-transform-origin: value
	transform-origin: value

transform-style(value)
	-webkit-transform-style: value
	-moz-transform-style: value
	-ms-transform-style: value
	-o-transform-style: value
	transform-style: value

perspective(value)
	-webkit-perspective: value
	-moz-perspective: value
	-ms-perspective: value
	-o-perspective: value
	perspective: value

perspective-origin(value)
	-webkit-perspective-origin: value
	-moz-perspective-origin: value
	-ms-perspective-origin: value
	-o-perspective-origin: value
	perspective-origin: value

backface-visibility(value)
	-webkit-backface-visibility: value
	-moz-backface-visibility: value
	-ms-backface-visibility: value
	-o-backface-visibility: value
	backface-visibility: value

matrix(values)
	value = join(', ', values)
	value = unquote(value)
	
	-webkit-transform: matrix(value)
	-moz-transform: matrix(value)
	-ms-transform: matrix(value)
	-o-transform: matrix(value)
	transform: matrix(value)

transform-translate(value)
	-webkit-transform: translate(value)
	-moz-transform: translate(value)
	-ms-transform: translate(value)
	-o-transform: translate(value)
	transform: translate(value)

transform-translateX(value)
	transform: translateX(value)
	-webkit-transform: translateX(value)
	-moz-transform: translateX(value)
	-ms-transform: translateX(value)
	-o-transform: translateX(value)

transform-translateY(value)
	transform: translateY(value)
	-webkit-transform: translateY(value)
	-moz-transform: translateY(value)
	-ms-transform: translateY(value)
	-o-transform: translateY(value)

transform-translate3d(value)
	-webkit-transform: translate3d(value)
	-moz-transform: translate3d(value)
	-ms-transform: translate3d(value)
	-o-transform: translate3d(value)
	transform: translate3d(value)

transform-scale(value)
	-webkit-transform: scale(value)
	-moz-transform: scale(value)
	-ms-transform: scale(value)
	-o-transform: scale(value)
	transform: scale(value)

transform-rotate(value)
	-webkit-transform: rotate(value)
	-moz-transform: rotate(value)
	-ms-transform: rotate(value)
	-o-transform: rotate(value)
	transform: rotate(value)

skew(value)
	-webkit-transform: skew(value)
	-moz-transform: skew(value)
	-ms-transform: skew(value)
	-o-transform: skew(value)
	transform: skew(value)