// LARANJA.INFO - MARKETING DIGITAL & WEBDESIGN
// Pack de Mixins para Stylus
//
// Muitas mixins foram retiradas de https://gist.github.com/batmandarkside/5028368
//
// @author Rafhael Marsigli <rafhael@laranja.info>
// @url laranja.info


// ------------------- //
// ----- DISPLAY ----- //
// ------------------- //

calc(value, size = 100%, operation = '-')
	return 'calc(%s %s %s)' % (size unquote(operation) value)

box-sizing(value)
	-webkit-box-sizing: value
	-moz-box-sizing: value
	box-sizing: value

overflow-scroll()
	-webkit-overflow-scrolling: touch
	overflow: scroll
	
user-select(value)
	-webkit-touch-callout: value
	-webkit-user-select: value
	-moz-user-select: value
	-ms-user-select: value
	-o-user-select: value
	user-select: value
	
appearance(value)
	-webkit-appearance: value
	-moz-appearance: value
	-ms-appearance: value
	-o-appearance: value
	appearance: value

square(value = 100%)
	width: value
	height: value

size(width = 100%, height = auto)
	width: width
	height: height

section-row(value = false)
	width: 100%
	height: auto
	float: left
	display: block
	if value
		padding-top: value
		padding-bottom: value
		
container-box(max-width = 1200px, margin = 0)
	margin: margin auto
	max-width: max-width
	
clearfix(display = block)
	&:after
		display: display
		content: ""
		clear: both

opacity(value)
	filter 'alpha(opacity= %s)' % percentage(value)
	-moz-opacity: value
	opacity: value