.parsley
	&-errors-list
		section-row()
	&-errors-list
		display: flex
		flex-direction: column
		&.filled
			margin-top: ($app-padding / 3)
		> li
			font-text()
			padding: ($app-padding / 2)
			background-color: blacky(2.5)
			font-size: 12px
			line-height: 16px
			max-width: calc($app-padding)
			color: $color-red
			margin-bottom: ($app-padding / 3)
			border-radius(3px)
			&:last-child
				margin-bottom: 0
		
input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="date"],
textarea,
select
	&.parsley-error
		border-color: $color-red
		&.focus-visible
			border-color: $color-red-700