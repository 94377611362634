.message-content
	display: flex
	align-items: center
	justify-content: center
	flex-flow: column
	padding: $global-padding
	figure
		$size = 120px
		square($size)
		img
			square(100%)
			float: left
			display: block
			object-fit: contain
		~ strong,
		~ span,
		~ h2,
		~ h3
			margin-top: $global-padding
	strong,
	span,
	h2,
	h3
		font-title(800)
		font-size: $global-size * 5
		line-height: $global-size * 6
		text-align: center
		color: $app-color-primary
		~ p
			margin-top: $global-size
	p
		color: $black-85
		text-align: center
	strong,
	span,
	h2,
	h3,
	p
		text-align: center
		max-width: 320px
