// LARANJA.INFO - MARKETING DIGITAL & WEBDESIGN
// Pack de Mixins para Stylus
//
// Muitas mixins foram retiradas de https://gist.github.com/batmandarkside/5028368
//
// @author Rafhael Marsigli <rafhael@laranja.info>
// @url laranja.info


// --------------------- //
// ----- ANIMATION ----- //
// --------------------- //

transition(value)
	-webkit-transition: value
	-moz-transition: value
	-ms-transition: value
	-o-transition: value
	transition: value

transition-property(value)
	transition-property value
	-webkit-transition-property value
	-moz-transition-property value
	-o-transition-property value

transition-duration(value)
	transition-property value
	-webkit-transition-property value
	-moz-transition-property value
	-o-transition-property value

transition-timing-function(value)
	transition-timing-function value
	-webkit-transition-timing-function value
	-moz-transition-timing-function value
	-o-transition-timing-function value

transition-delay(value)
	transition-delay value
	-webkit-transition-delay value
	-moz-transition-delay value
	-o-transition-delay value
	
animate(value)
	-moz-animation value
	-webkit-animation value
	animation value

animate-delay(delay = .0s)
	-moz-animation-delay delay
	-webkit-animation-delay delay
	-ms-animation-delay delay
	-o-animation-delay delay
	animation-delay delay

animate-duration(duration = 1s)
	-moz-animation-duration duration
	-webkit-animation-duration duration
	-ms-animation-duration duration
	-o-animation-duration duration
	animation-delay duration
