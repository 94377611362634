.pagination
	section-row()
	padding: $app-padding
	text-align: center
	&.align-left
		text-align: left
	&.align-right
		text-align: right
	&__container
		section-row()
	li
		float: unset
		display: inline-block
		background-color: $black-10
		margin-right: ($app-padding / 2)
		overflow: hidden
		border-radius(2px)
		&.disabled
			background-color: $black-05
			span,
			a
				color: $black-25
		&.active
			background-color: black
			color: white
			box-shadow(0 2px 4px transparentify(black, .25))
			span,
			a
				color: $black-05
		&:last-child
			margin-right: 0
		span,
		a
			float: left
			display: block
			roboto(500)
			padding: ($app-padding * .75) $app-padding
			color: $black-40
			font-size: 14px
			line-height: 18px
			border: 1px solid transparentify(black, .075)
		&:hover:not(.active):not(.disabled)
			transition(0.25s all ease)
			box-shadow(0 2px 4px transparentify(black, .25))
			background-color: blacky(2.5)
			span,
			a
				color: $black-75
