.app-toolbar
	section-row()
	box-shadow(0 2px 6px transparentify(black, .125))
	background-color: white
	&-inner
		float: left
		width: 100%
		padding-block: $global-padding
		display: flex
		align-items: center
		justify-content right
		flex-direction: row
	&-logo
		position: absolute
		top: 0
		left: 0
		padding-block: $global-padding + ($global-size * 2);
		width: 280px
		background-color: $app-green
		padding: $global-padding
		border-radius(0 0 $global-padding $global-padding)
		box-shadow(0 2px 4px transparentify(black, .125))
	&-nav
		ul
			display: flex
			flex-direction: row
			li
				a:not(.btn)
					font-title()
					padding: $global-size * 2
					color: $app-dark-green
					font-size: $global-size * 4
					line-height: $global-size * 5
					border-radius($global-size)
					&:hover
						color: $app-orange
						background-color: $black-05
		~ .app-shopping-cart-icon
			margin-left: $global-padding
	.app-shopping-cart-icon
		$size = 44px
		square($size)
		background-color: $black-05
		border-radius($global-size)
		i
			square(100%)
			text-align: center
			line-height: $size
			color: $app-orange
		&:hover
			i
				color: $app-green
		&-count
			square($global-padding)
			font-title()
			position: absolute
			top: ($global-padding / 3) * -1
			right: ($global-padding / 3) * -1
			color: white
			background-color: $black-75
			font-size: $global-size * 3
			line-height: $global-padding
			text-align: center
			border-radius(50%)

.app-toolbar-bottom
	padding-block: $global-padding
	z-index: 0
	.app-toolbar-nav
		float: right
		ul
			li
				margin-left: $global-size * 2


.app-toolbar-nav-mobile
	&-pull
		display: none
		&:hover
			cursor: pointer
	+below($lg)
		&-pull
			display: block
		> ul
			display: none
			position: absolute
			top: $global-padding * 2
			right: 0
			flex-direction: column
			background-color: white
			width: 240px
			z-index: 10
			border-radius($global-size)
			box-shadow(0 2px 4px transparentify(black, .125))
			&.active
				display: block
			> li
				> a
					section-row()
					text-align: center

