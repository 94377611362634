.card
	section-row()
	background-color: white
	border-radius($global-size)
	box-shadow(0 2px 4px transparentify(black, .125))
	margin-bottom: $global-padding * 2
	padding: $global-padding

	&-header,
	&-body,
	&-footer
		section-row()

.colored-card
	section-row()
	background-color: $black-25
	border-radius $global-size
	margin-botom: $global-padding
	&.blue
		background-color: $color-blue
	&.green
		background-color: $color-green
	&.orange
		background-color: $color-orange
	&.cyan
		background-color: $color-cyan
	strong,
	p
		section-row()
		color: white
		padding: $global-padding
	strong
		font-title()
		font-size: $global-size * 4
		line-height: $global-size * 4.5
		background-color: transparentify(white, .125)
	p
		font-size: $global-size * 5
		line-height: $global-size * 5.5
