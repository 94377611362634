.form-choices
	section-row()

.form-choice
	$h = 140px
	section-row()
	&-input
		position: absolute
		top: $global-size * 2
		left: $global-size * 2
		z-index: 2
		&:checked
			~ .form-choice-label
				color: white
				background-color: $app-green
				border: $global-size solid darken($app-green, 15%)
				box-shadow(0 2px 4px transparentify(black, .125))
				&-helper,
				&-input
					color: white !important
	&-helper
		section-row()
		font-title()
		color: $app-green
		margin: $global-size 0 0 0 !important
	&-label
		float: left
		display: flex
		align-items: center
		justify-content: center
		flex-direction: column
		font-title()
		size(100%, $h)
		background-color: $black-10
		text-align: center
		color: $black-75
		font-size: $global-size * 6
		border: $global-size solid $black-20
		border-radius($global-size)
		&-small
			font-size: $global-size * 5 !important
		&-big
			font-size: $global-size * 7 !important
		&:hover
			cursor: pointer
		&-input,
		&-helper
			section-row()
			color: $app-orange
		&-input
			$h = 60px
			font-family: 'Poppins', sans-serif !important
			font-weight: 700 !important
			size(80% !important, $h)
			background-color: transparent !important
			line-height: $h
			padding: 0 $global-size
			margin: 0 !important
			z-index: 5
			text-align: center
			border: 3px solid transparentify($app-dark-green, .5) !important
			&:focus
				box-shadow(none !important)
				background-color: transparentify(black, .125) !important
			~ .form-choice-label-helper
				margin-top: $global-size * 3
		&-helper
			font-title()
			font-size: $global-padding !important
			line-height: $global-padding !important
			margin-top: $global-size
			text-algin: center
			padding: 0 ($global-size * 2)
