h1,
h2,
h3,
h4,
h5,
h6
	section-row()
	font-title()
	color: $app-color-primary
	margin-bottom: $global-padding

h1
	font-weight: 800
	font-size: $global-size * 12
	line-height: $global-size * 13
	margin-bottom: $global-padding * 2
h2
	font-size: $global-size * 8
	line-height: $global-size * 9.5
h3
	font-size: $global-size * 7
	line-height: $global-size * 8.5
h4
	font-size: $global-size * 5
	line-height: $global-size * 6
h5
	font-size: $global-size * 4
	line-height: $global-size * 5
h6
	font-size: $global-size * 3
	line-height: $global-size * 4

p,
li
	font-text()
	color: $black-85
	font-size: $global-size * 4
	line-height: $global-size * 6
	small
		font-size: .75rem

.errors-list
	background-color: transparentify($color-red, .5)
	border-radius($global-size)
	padding: $global-padding
	margin-bottom: $global-padding
	h2,
	h3,
	p
		color: white

.text-align-right
	text-align: right
